import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
	Button,
	Divider,
	List,
	ListItem,
	ListItemText
} from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'

const ListUsersByIIN = ({ open, onClose, data }) => {
	return (
		<Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
			<DialogTitle>Поиск по ИИН</DialogTitle>
			<List>
				{data.map(item => (
					<>
						<ListItem button>
							<ListItemText
								primary={`${item.firstName} ${item.lastName}`}
								secondary={item.username}
							/>
						</ListItem>
						<Divider />
					</>
				))}
			</List>
			<DialogActions>
				<Button color={'primary'} onClick={onClose}>
					Закрыть
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default ListUsersByIIN
