import './App.css'
import { Redirect, Route, Switch } from 'react-router-dom'
import SignIn from './pages/SignIn'
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PrivateRoute from './components/PrivateRoute'
import { useContext, useEffect, useRef, useState } from 'react'
import Home from './pages/Home'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from './store/rootStore'
import { setAuthToken } from './lib/utils'
import Profile from './pages/Profile'
import * as queryString from 'query-string'

const App = observer(({ location }) => {
	const { authStore } = useContext(RootStoreContext)
	const { t } = useTranslation()
	const _isMounted = useRef(true)
	const [appBar, SetAppBar] = useState(true)
	const isAuthenticated =
		authStore.user.data &&
		authStore.user.data.hasOwnProperty('roleList') &&
		authStore.user.data.roleList
			.map(
				value =>
					value.hasOwnProperty('project') &&
					value.project.code === 'UA' &&
					value.code === 'ADMIN'
			)
			.includes(true)

	useEffect(() => {
		const values = queryString.parse(location.search)
		if (values.token) {
			authStore.setTokenFromQuery(values.token)
			SetAppBar(false)
		}
		return () => {
			_isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (authStore.user.token) {
			setAuthToken(authStore.user.token)
		}
	}, [authStore.user.token])

	return (
		<>
			{appBar && (
				<AppBar
					position="static"
					style={{
						backgroundColor: '#333'
					}}
				>
					<Toolbar>
						<Typography variant="h6" style={{ flexGrow: '1' }}>
							{t('text')}
						</Typography>
						{isAuthenticated ? (
							<Button
								color="inherit"
								onClick={() => window.location.reload(true)}
							>
								{t('signOut')}
							</Button>
						) : (
							''
						)}
					</Toolbar>
				</AppBar>
			)}
			<Switch>
				<PrivateRoute
					auth={isAuthenticated}
					exact
					path="/home"
					key={'home'}
					component={Home}
				/>
				<PrivateRoute
					auth={isAuthenticated}
					exact
					path={['/create-user', '/edit-user/:id']}
					key={'profile'}
					component={Profile}
				/>
				<Route exact path="/signin" render={props => <SignIn {...props} />} />
				<Route path="/" render={() => <Redirect to="/home" />} />
			</Switch>
		</>
	)
})

export default App
