import React, { useContext, useEffect, useRef, useState } from 'react'
import {
	Avatar,
	Button,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import userImg from '../img/user.png'
import { useTranslation } from 'react-i18next'
import ProfileFields from '../components/Profile/ProfileFields'
import CardAccess from '../components/Profile/CardAccess'
import { RootStoreContext } from '../store/rootStore'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import ModalHOC from '../components/ModalHOC'
import AddAccess from '../components/Profile/modals/AddAccess'
import CopyAccess from '../components/Profile/modals/CopyAccess'
import { toJS } from 'mobx'
import EditPass from '../components/Profile/modals/EditPass'
import CardReports from '../components/Profile/CardReports'
import AddReports from '../components/Profile/modals/AddReports'
import generator from 'generate-password'
import ListUsersByIIN from '../components/Profile/modals/ListUsersByIIN'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		padding: 10
	},
	rootPaper: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(2)
	},
	large: {
		width: theme.spacing(17),
		height: theme.spacing(17)
	},
	buttonGreen: {
		backgroundColor: 'green',
		color: 'white'
	},
	buttonBack: {
		backgroundColor: 'black',
		color: 'white'
	},
	subText: {
		textAlign: 'center',
		color: '#7d8784'
	},
	accessCont: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	buttonContainer: {
		marginTop: theme.spacing(2)
	}
}))

const Profile = observer(() => {
	const { homeStore, profileStore } = useContext(RootStoreContext)
	const { enqueueSnackbar } = useSnackbar()
	const history = useHistory()
	const location = useLocation()
	const params = useParams()
	const { t } = useTranslation()
	const classes = useStyles()
	const _isMounted = useRef(true)
	const [isEditRole, setIsEditRole] = useState(false)
	const [openAddAccess, setOpenAddAccess] = useState(false)
	const [openCopyAccess, setOpenCopyAccess] = useState(false)
	const [openEditPass, setOpenEditPass] = useState(false)
	const [openIINUsers, setOpenIINUsers] = useState({
		open: false,
		data: null
	})
	const [openAddReports, setOpenAddReports] = useState(false)
	const [isBiReports, setIsBiReports] = useState(false)
	const [tempBiReports, setTempBiReports] = useState([])
	const [tempReports, setTempReports] = useState([])
	const isCreateUser = location.pathname === '/create-user'

	useEffect(() => {
		if (!isCreateUser) {
			getUser()
		} else {
			profileStore.getAllUsers().then(res => {
				setErrOnRes(res)
			})
		}
		return () => {
			_isMounted.current = false
		}
	}, [])

	const getUser = () => {
		profileStore.getUser(params.id).then(res => {
			if (setErrOnRes(res)) {
				profileStore.getReports().then(res => {
					setErrOnRes(res)
				})
				profileStore.getDashboardReports().then(res => {
					setErrOnRes(res)
				})
			}
		})
	}
	const setErrOnRes = res => {
		if (res && res.hasOwnProperty('errStatus')) {
			if (res.errStatus === 401) {
				history.push('/signin')
				return false
			} else {
				enqueueSnackbar(res.msg, { variant: 'error' })
				return false
			}
		}
		return true
	}

	const handleFileReader = e => {
		const file = e.target.files[0]
		const data = new FormData()
		data.append('file', file)
		profileStore.uploadPhoto(data).then(res => setErrOnRes(res))
	}

	const handleChangeTextValue = e => {
		const { name, value } = e.target
		if (name === 'male') {
			profileStore.setUserValue(e.target.name, e.target.value === 'm')
			return
		}
		if (name === 'isSystemUser') {
			profileStore.setUserValue(
				e.target.name,
				e.target.value === 'y' ? true : e.target.value === 'n' ? false : null
			)
			return
		}
		if (name === 'username') {
			const regex = /[А-Яа-яёЁ]/g
			const found = value.match(regex)
			if (!!found) {
				enqueueSnackbar(t('profile.rusUsernameErr'), { variant: 'error' })
			}
			return profileStore.setUserValue(
				name,
				value?.replace(/[^a-zA-Z0-9\s.!@]/g, '')
			)
		}
		profileStore.setUserValue(name, value)
	}

	const handleChangeSelectValue = (name, value) => {
		profileStore.setUserValue(name, value)
	}
	const handleSetTempSubdivision = value => {
		profileStore.setTempSubdivision(value)
	}
	const clickBack = () => {
		profileStore.clearState()
		history.push('/home')
	}

	const checkUserName = () => {
		profileStore.getAllUsers(profileStore.user.username).then(res => {
			if (setErrOnRes(res)) {
				if (typeof res === 'number') {
					if (res === 0) {
						enqueueSnackbar(t('profile.freeLogin'), { variant: 'success' })
					} else {
						enqueueSnackbar(t('profile.existLogin'), { variant: 'error' })
					}
				}
			}
		})
	}

	const handleSwitchStatusUser = () => {
		if (profileStore.user.subdivision !== null) {
			profileStore.user.subdivision = null
			profileStore.updateUser().then(res => {
				if (setErrOnRes(res)) {
					profileStore.switchStatusUser().then(res => {
						if (setErrOnRes(res)) {
							profileStore.getUser(params.id).then(res => {
								setErrOnRes(res)
							})
						}
					})
				}
			})
		} else {
			profileStore.switchStatusUser().then(res => {
				if (setErrOnRes(res)) {
					profileStore.getUser(params.id).then(res => {
						setErrOnRes(res)
					})
				}
			})
		}
	}
	const checkRole = (arr, role) => {
		return arr.some(function (arrValue) {
			return role === arrValue.code
		})
	}
	const handleCreateUser = () => {
		if (
			!profileStore.user.username ||
			!profileStore.user.password ||
			!profileStore.user.firstName ||
			!profileStore.user.lastName ||
			!profileStore.user.branch
		) {
			enqueueSnackbar(t('profile.reqFields'), { variant: 'error' })
			return
		} else if (profileStore.user.password.length < 6) {
			enqueueSnackbar(t('profile.passLengthMinSix'), { variant: 'error' })
			return
		} else if (profileStore.user.roles.length === 0) {
			enqueueSnackbar(t('profile.reqAccess'), { variant: 'error' })
			return
		} else if (
			profileStore.user.iin.length === 0 &&
			profileStore.user.isSystemUser !== true
		) {
			enqueueSnackbar(t('profile.innReq'), { variant: 'error' })
			return
		} else if (
			checkRole(profileStore.user.roles, 'PURCHASER') &&
			profileStore.user.subdivision == null
		) {
			enqueueSnackbar(t('profile.reqPurchaser'), { variant: 'error' })
			return
		} else if (
			checkRole(profileStore.user.roles, 'MANAGER') &&
			profileStore.user.subdivision == null
		) {
			enqueueSnackbar(t('profile.reqManager'), { variant: 'error' })
			return
		} else {
			profileStore.createUser().then(res => {
				if (setErrOnRes(res)) {
					enqueueSnackbar(t('profile.successCreateUser'), {
						variant: 'success'
					})

					clickBack()
				}
			})
		}
	}

	const handleUpdateUser = () => {
		if (
			checkRole(profileStore.user.roles, 'PURCHASER') &&
			profileStore.user.subdivision == null
		) {
			enqueueSnackbar(t('profile.reqPurchaser'), { variant: 'error' })
			return
		} else if (
			checkRole(profileStore.user.roles, 'MANAGER') &&
			profileStore.user.subdivision == null
		) {
			enqueueSnackbar(t('profile.reqManager'), { variant: 'error' })
			return
		} else {
			profileStore.updateUser().then(res => {
				if (setErrOnRes(res)) {
					enqueueSnackbar(t('profile.successUpdateUser'), {
						variant: 'success'
					})
					clickBack()
				}
			})
		}
	}

	const handleEditRole = index => {
		setIsEditRole(!isEditRole)
		profileStore.setDataNewRoleToEdit(index, toJS(homeStore.branches))
		if (isCreateUser) {
			profileStore.deleteUserRole(index)
			setOpenAddAccess(!openAddAccess)
		} else {
			profileStore.deleteUserRoleServ(index).then(res => {
				if (setErrOnRes(res)) {
					setOpenAddAccess(!openAddAccess)
					profileStore.deleteUserRole(index)
				}
			})
		}
	}

	const handleDeleteRole = index => {
		if (isCreateUser) {
			profileStore.deleteUserRole(index)
		} else {
			profileStore.deleteUserRoleServ(index).then(res => {
				if (setErrOnRes(res)) {
					profileStore.deleteUserRole(index)
				}
			})
		}
	}

	const handleCloseAddAccess = () => {
		setIsEditRole(false)
		profileStore.clearNewRoleValue()
		setOpenAddAccess(!openAddAccess)
	}

	const handleAgreeAddAccess = () => {
		if (
			profileStore.newRole.selectedBranches.length > 0 &&
			profileStore.newRole.selectedProject !== null &&
			profileStore.newRole.selectedRole !== null
		) {
			setIsEditRole(false)
			if (isCreateUser) {
				profileStore.addToUserNewRole()
				setOpenAddAccess(!openAddAccess)
				profileStore.clearNewRoleValue()
			} else {
				profileStore.addUserRoleServ().then(res => {
					if (setErrOnRes(res)) {
						setOpenAddAccess(!openAddAccess)
						profileStore.addToUserNewRole()
						profileStore.clearNewRoleValue()
						getUser()
					}
				})
			}
		} else {
			enqueueSnackbar(t('profile.reqAccessFields'), { variant: 'error' })
			return
		}
	}

	const handleCloseCopyAccess = () => {
		profileStore.clearUserIdToCopy()
		setOpenCopyAccess(!openCopyAccess)
	}

	const handleAgreeCopyAccess = () => {
		profileStore.getRolesUser().then(res => {
			if (setErrOnRes(res)) {
				setOpenCopyAccess(!openCopyAccess)
			}
		})
	}

	const handleCloseEditPass = () => {
		profileStore.clearNewPassword()
		setOpenEditPass(!openEditPass)
	}

	const handleAgreeEditPass = () => {
		if (profileStore.newPassword.length < 6) {
			enqueueSnackbar(t('profile.passLengthMinSix'), { variant: 'error' })
			return
		} else {
			profileStore.editPass().then(res => {
				if (setErrOnRes(res)) {
					setOpenEditPass(!openEditPass)
				}
			})
		}
	}

	const handleOpenBiAddReports = () => {
		setTempBiReports(
			toJS(profileStore.user.dashboardCodes)
				? toJS(profileStore.user.dashboardCodes)
				: []
		)
		setIsBiReports(true)
		setOpenAddReports(!openAddReports)
	}

	const handleOpenAddReports = () => {
		setTempReports(
			toJS(profileStore.user.reportCodes)
				? toJS(profileStore.user.reportCodes)
				: []
		)
		setIsBiReports(false)
		setOpenAddReports(!openAddReports)
	}

	const handleCloseAddReports = () => {
		isBiReports
			? profileStore.setReportCodesBi(tempBiReports)
			: profileStore.setReportCodes(tempReports)
		isBiReports ? setTempBiReports(() => []) : setTempBiReports(() => [])
		setIsBiReports(false)
		setOpenAddReports(!openAddReports)
	}
	const arrayEquals = (a, b) => {
		return (
			Array.isArray(a) &&
			Array.isArray(b) &&
			a.length === b.length &&
			a.every((val, index) => val === b[index])
		)
	}
	const handleAgreeAddReports = () => {
		if (isBiReports) {
			if (!arrayEquals(profileStore.user.dashboardCodes, tempBiReports)) {
				profileStore.updateDashboardReports().then(res => {
					if (setErrOnRes(res)) {
						setTempBiReports(() => [])
						setIsBiReports(false)
						setOpenAddReports(!openAddReports)
					}
				})
			} else {
				setTempBiReports(() => [])
				setIsBiReports(false)
				setOpenAddReports(!openAddReports)
			}
		} else {
			if (!arrayEquals(profileStore.user.reportCodes, tempReports)) {
				profileStore.updateReports().then(res => {
					if (setErrOnRes(res)) {
						setTempReports(() => [])
						setIsBiReports(false)
						setOpenAddReports(!openAddReports)
					}
				})
			} else {
				setTempReports(() => [])
				setIsBiReports(false)
				setOpenAddReports(!openAddReports)
			}
		}
	}

	const ModalAddAccess = ModalHOC({
		open: openAddAccess,
		title: t('profile.addAccess'),
		id: 'AddAccess_modal',
		handleClose: handleCloseAddAccess,
		handleAgree: handleAgreeAddAccess
	})(AddAccess)

	const ModalCopyAccess = ModalHOC({
		open: openCopyAccess,
		title: t('profile.copyAccess'),
		id: 'CopyAccess_modal',
		handleClose: handleCloseCopyAccess,
		handleAgree: handleAgreeCopyAccess
	})(CopyAccess)

	const ModalEditPass = ModalHOC({
		open: openEditPass,
		title: t('profile.changePass'),
		id: 'EditPass_modal',
		handleClose: handleCloseEditPass,
		handleAgree: handleAgreeEditPass
	})(EditPass)

	const ModalAddReports = ModalHOC({
		open: openAddReports,
		title: isBiReports ? t('profile.reportsBI') : t('profile.reportsAsterX'),
		id: 'AddReports_modal',
		handleClose: handleCloseAddReports,
		handleAgree: handleAgreeAddReports
	})(AddReports)

	const handleGeneratePass = () => {
		const pass = generator.generate({
			length: 6,
			numbers: true,
			strict: true
		})
		profileStore.setUserValue('password', pass)
	}
	const handleFindByIIN = () => {
		if (profileStore.user.iin.length < 12) {
			return enqueueSnackbar('введите ИИН ', { variant: 'error' })
		}
		profileStore.getUsersByIIN().then(res => {
			if (Array.isArray(res) && res.length > 0)
				setOpenIINUsers({ open: true, data: res })
			else {
				enqueueSnackbar('пользователей по иин не найдено', { variant: 'info' })
				setOpenIINUsers({ open: false, data: [] })
			}
		})
	}
	const buttonsToCreateUser = (
		<Grid
			container
			justify="flex-start"
			alignItems="center"
			className={classes.buttonContainer}
			spacing={2}
		>
			<Grid item>
				<Button
					variant="contained"
					className={classes.buttonGreen}
					onClick={() => setOpenAddAccess(!openAddAccess)}
				>
					{t('profile.addAccess')}
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					className={classes.buttonGreen}
					onClick={() => setOpenCopyAccess(!openCopyAccess)}
				>
					{t('profile.copyAccess')}
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					color={'primary'}
					onClick={handleCreateUser}
				>
					{t('profile.createProfile')}
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="outlined"
					color={'primary'}
					onClick={handleGeneratePass}
				>
					{t('profile.generatePassword')}
				</Button>
			</Grid>
			<Grid item>
				<Button variant="outlined" color={'primary'} onClick={handleFindByIIN}>
					{t('profile.checkByIIN')}
				</Button>
			</Grid>
		</Grid>
	)
	const buttonsToEditUser = (
		<Grid
			container
			justify="flex-start"
			alignItems="center"
			className={classes.buttonContainer}
			spacing={2}
		>
			<Grid item>
				<Button
					variant="contained"
					className={classes.buttonGreen}
					onClick={() => setOpenEditPass(!openEditPass)}
				>
					{t('profile.changePass')}
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					className={classes.buttonGreen}
					onClick={() => setOpenAddAccess(!openAddAccess)}
				>
					{t('profile.addAccess')}
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					color={profileStore.user.isActive ? 'secondary' : 'primary'}
					onClick={handleSwitchStatusUser}
				>
					{profileStore.user.isActive
						? t('profile.block')
						: t('profile.unBlock')}
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					color={'primary'}
					onClick={handleUpdateUser}
				>
					{t('profile.save')}
				</Button>
			</Grid>
		</Grid>
	)

	const Reports = () => (
		<Grid
			container
			justify="flex-start"
			alignItems="center"
			className={classes.buttonContainer}
			spacing={2}
		>
			<Grid item xs={12} md={12}>
				<Typography variant={'h5'}>{t('profile.reports')}</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<CardReports
					label={t('profile.reportsAsterX')}
					onClickEdit={handleOpenAddReports}
					reportList={profileStore.reportsList ? profileStore.reportsList : []}
					reportCodes={
						profileStore.user.reportCodes ? profileStore.user.reportCodes : []
					}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<CardReports
					label={t('profile.reportsBI')}
					onClickEdit={handleOpenBiAddReports}
					reportList={
						profileStore.dashboardReportsList
							? profileStore.dashboardReportsList
							: []
					}
					reportCodes={
						profileStore.user.dashboardCodes
							? profileStore.user.dashboardCodes
							: []
					}
				/>
			</Grid>
		</Grid>
	)

	return (
		<Container className={classes.root} maxWidth={'lg'}>
			<Grid container justify="flex-start" alignItems="center" spacing={2}>
				<Grid item xs={2} md={1}>
					<Button
						variant="contained"
						className={classes.buttonBack}
						onClick={clickBack}
					>
						<ArrowBackIosIcon />
					</Button>
				</Grid>
				<Grid item xs md>
					<Typography variant="h4">
						{isCreateUser
							? t('profile.createProfile')
							: t('profile.editProfile')}
					</Typography>
				</Grid>
			</Grid>
			{profileStore.loading && (
				<CircularProgress
					style={{
						position: 'absolute',
						zIndex: 9,
						top: '50%',
						left: '48%'
					}}
					color="secondary"
				/>
			)}
			{!profileStore.loading && (
				<Paper elevation={3} className={classes.rootPaper}>
					<Grid container direction="row" spacing={3}>
						<Grid
							item
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
							spacing={2}
						>
							<Grid
								item
								container
								direction="column"
								justify="center"
								alignItems="center"
								xs={12}
								md={3}
								spacing={2}
							>
								<Grid item>
									<Avatar
										alt="UserPhoto"
										style={{ border: '1px #cdcdcd solid' }}
										src={
											profileStore.user.photo
												? profileStore.user.photo
												: userImg
										}
										className={classes.large}
									/>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										color="default"
										component="label"
										startIcon={<CloudUploadIcon />}
									>
										{t('profile.loadPhoto')}
										<input
											id="scanFiles"
											type="file"
											accept=".jpeg,.jpg"
											hidden
											onChange={handleFileReader}
										/>
									</Button>
								</Grid>
							</Grid>
							<Grid
								item
								container
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={2}
								md={9}
							>
								<ProfileFields
									isCreateUser={isCreateUser}
									fieldsValue={profileStore.user}
									onSelect={handleChangeSelectValue}
									onClickSearchUser={checkUserName}
									onChangeText={handleChangeTextValue}
									branches={homeStore.branches}
									subdivisions={homeStore.subdivisions}
									setTempSubdivision={handleSetTempSubdivision}
								/>
							</Grid>
						</Grid>
					</Grid>

					{profileStore.user.roles && profileStore.user.roles.length > 0 && (
						<Grid
							container
							justify="flex-start"
							alignItems="center"
							spacing={2}
							className={classes.accessCont}
						>
							<Grid item xs={12} md={12}>
								<Typography variant={'h5'}>
									{t('profile.accessList')}
								</Typography>
							</Grid>
							{profileStore.user.roles &&
								profileStore.user.roles.map((role, index) => (
									<Grid item xs={12} md={12} key={`grid_role_${index}`}>
										<CardAccess
											role={role}
											branches={homeStore.branches}
											key={`role_${index}`}
											onClickEdit={() => handleEditRole(index)}
											deleteRole={() => handleDeleteRole(index)}
										/>
									</Grid>
								))}
						</Grid>
					)}
					{!isCreateUser && <Reports />}
					{isCreateUser ? buttonsToCreateUser : buttonsToEditUser}
				</Paper>
			)}
			<ModalAddAccess isEditRole={isEditRole} />
			<ModalCopyAccess />
			<ModalEditPass />
			<ModalAddReports isBi={isBiReports} />
			{openIINUsers.open && (
				<ListUsersByIIN
					open={openIINUsers.open}
					data={openIINUsers.data}
					onClose={() => setOpenIINUsers({ open: false, data: [] })}
				/>
			)}
		</Container>
	)
})
export default Profile
